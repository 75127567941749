.offer-list {
    position: relative;
    background-color: #d2d2d2;
    border-bottom-left-radius: 20px;
    padding: 15px 15px 15px;
    min-height: 150rem;

    > .row {
        margin-left: -9px;
        margin-right: -9px;

        > .col {
            padding-left: 9px;
            padding-right: 9px;
        }
    }

    &::after {
        position: absolute;
        content: '';
        background-color: #d2d2d2;
        width: 25px;
        height: 25px;
        right: 0;
        top: -25px;
        z-index: -1;
    }
}
