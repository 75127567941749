#future-models-form {
    padding: 20px;
    border-radius: 6px;
    background-color: #fff;

    .btn.btn-warning.bold {
        float: right;
        color: white;
    }

    .form-group {
        input.form-control {
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0;
            box-shadow: none;
        }
    }
}
