.contract-info-tab-wrapper {
    .tab-header {
        align-items: center;
        border-top-left-radius: $gw-border-radius;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;

        .nav-tabs {
            border-bottom: none;
            padding-top: 20px;
            flex: auto;

            .nav-link {
                border-radius: 0;
                background-color: $color-grey-tint;
                color: #fff;
                padding: 10px 20px;
                width: 100%;
                font-size: .813rem;
                letter-spacing: .1em;
                border-color: #dedede;

                &.active {
                    background-color: $color-grey-light;
                    color: $color-grey;
                }

                &:hover, &.active {
                    border-color: $color-grey-light;
                }
            }

            & > * {
                margin-right: 10px;
                flex: 1 1 8.75rem;

                &:nth-child(odd) .nav-link {
                    border-top-left-radius: $gw-border-radius;
                }

                &:nth-child(even) .nav-link {
                    border-top-right-radius: $gw-border-radius;
                }
            }
        }

        .provider-logo {
            text-align: right;
            flex-basis: 8.75rem;
        }
    }

    .tab-content {
        background-color: $color-grey-light;
        padding: 30px;
        font-size: .75rem;

        .tab-pane:not(.single-column) {
            column-count: 2;
            column-gap: 60px;
        }

        .details-title {
            font-size: .875rem;
            font-weight: bold;
            margin: 20px 0 10px;
        }

        .feature-group {
            .group-title {
                font-size: .875rem;
                font-weight: 600;
                margin-bottom: 20px;
            }

            .table {
                td:last-child {
                    text-align: right;
                }
            }
        }
    }

    .tab-footer {
        border-bottom-right-radius: $gw-border-radius;
        color: #fff;
        font-size: 1rem;
        padding: 18px;
        text-align: center;
    }

    .accordion-button::after {
        background-image: $icon-plus;
        background-size: 1.75rem;
        width: 1.75rem;
        height: 1.75rem;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: $icon-minus;
    }

    &.accordion {
        .accordion-item {
            border: none;
            border-radius: $gw-border-radius 0 $gw-border-radius 0;
            margin-bottom: 8px;

            .accordion-header .accordion-button {
                background-color: $color-grey-light;
                border-top-left-radius: $gw-border-radius;
                box-shadow: none;
                color: $color-topdeals;
                font-size: 1.275rem;
                font-weight: 600;

                &.collapsed {
                    background-color: $color-topdeals;
                    border-radius: $gw-border-radius 0 $gw-border-radius 0;
                    color: #fff;
                }

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            .accordion-collapse {
                background-color: $color-grey-light;
            }
        }
    }
}
