.provider-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;

    li {
        margin-right: 4px;
        background: $color-grey;
        flex: 1;
        height: 50px;
        padding: 0 15px;

        &.active {
            &:first-child {
                background: $color-topdeals;
            }

            &.t-mobile {
                background: $color-tmobile;
            }

            &.odido {
                background: $color-odido;
            }

            &.tele2 {
                background: $color-tele2;
            }

            &.vodafone {
                background: $color-vodafone;
            }

            &.kpn {
                background: $color-kpn;
            }

            &.lebara {
                background: $color-lebara;
            }

            &.ben {
                background: $color-ben;
            }

            &.hollandsnieuwe {
                background: $color-hollandsnieuwe;
            }

            &.los-toestel {
                background: $color-topdeals;
            }
        }

        &.line-break {
            flex-basis: 100%;
            height: 12px;
            background: none;
            font-size: 0;
            border: 0;
            display: none;
        }

        &.even {
            border-top-left-radius: 20px;
        }

        &.odd {
            border-top-right-radius: 20px;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background: #f6b000;
        }

        a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            color: #fff;
            text-decoration: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .provider-list {
        flex-flow: row wrap;
        padding: 10px;

        &:not([class*="content-"]) {
            background: $color-topdeals;
        }

        li {
            border: 4px solid #fff;
            margin-right: 0;

            &.line-break {
                display: block;
            }

            &.fp-2, &.fp-6 {
                border-top-left-radius: 0;
                border-bottom-left-radius: 20px;
            }

            &.fp-3, &.fp-7 {
                border-top-right-radius: 0;
                border-bottom-right-radius: 20px;
            }

            &.even {
                border-right-width: 2px;
            }

            &.odd {
                border-left-width: 2px;
            }
        }

        &.t-mobile {
            background: $color-tmobile;
        }

        &.odido {
            background: $color-odido;
        }

        &.tele2 {
            background: $color-tele2;
        }

        &.vodafone {
            background: $color-vodafone;
        }

        &.kpn {
            background: $color-kpn;
        }

        &.lebara {
            background: $color-lebara;
        }

        &.ben {
            background: $color-ben;
        }

        &.los-toestel {
            background: $color-topdeals;
        }
    }
}
