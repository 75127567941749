.slogan {
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .slogan-text {
        font-size: 24px;
        font-family: Arial, sans-serif;
        font-weight: bold;
    }

    .slogan-image {
        margin-left: 10px;
    }
}

@media screen and (max-width: 767px) {
    .slogan {
        border: 0;

        .slogan-text {
            font-size: 16px;
        }
    }
}
