.device-discount-overview {
    margin-bottom: 1rem;
    padding: 2rem 0 0;
    list-style: none;
    display: flex;
    justify-content: space-between;

    .discount-item {
        position: relative;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-collapse: collapse;
        margin: 0;
        padding-top: 30px;
        padding-bottom: 5px;
        height: 70px;
        flex: 1;
        z-index: 1;

        &:first-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 20px;
        }

        &:not(.line-break) {
            border: 3px solid #fff;
        }

        .item-price-tag {
            position: absolute;
            background-color: #fff;
            color: #f00;
            white-space: nowrap;
            width: 120px;
            height: 55px;
            left: calc(50% - 120px / 2);
            top: -30px;
            font-size: 2.5rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &::before {
                content: '';
                position: absolute;
                background-color: #fff;
                width: 35px;
                height: 35px;
                border-radius: 100%;
                top: calc(50% - 35px / 2);
                z-index: -1;
                left: -12px;
            }

            &::after {
                content: '';
                position: absolute;
                background-color: #fff;
                width: 35px;
                height: 35px;
                border-radius: 100%;
                top: calc(50% - 35px / 2);
                z-index: -1;
                right: -12px;
            }
        }

        .item-label {
            font-size: 15px;
        }

        .item-price {
            font-size: 22px;
            line-height: 1.1;
        }

        .item-content {
            width: 100%;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            color: #fff;
            text-decoration: none;
        }
    }
}

.discounts-swiper-container {
    height: 100px;
    border: 3px solid #fff;
    border-radius: 0 20px;
    overflow: hidden;
    margin-bottom: 1rem;

    .discount-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        padding: .25rem .75rem;

        .text-wrapper {
            color: #fff;
            font-size: .875rem;
            flex: 3;
            font-weight: bold;
        }

        .price-tag-wrapper {
            flex: 2;
            transform: rotate(-5deg);
        }

        .links-wrapper {
            flex-basis: 100px;
            align-self: stretch;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            img {
                width: auto;
                height: auto;
            }

            a {
                font-size: 1.25rem;
                text-decoration: underline;
                color: #fff;
            }
        }

        @media screen and (max-width: 575px) {
            .text-wrapper {
                display: none;
            }
        }
    }
}

.price-tag-wrapper {
    position: relative;
    max-width: 160px;
    min-width: 160px;
    color: #f00;

    &::before, &::after {
        content: '';
        position: absolute;
        background-color: #fff;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        top: calc(50% - 60px / 2);
        z-index: -1;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }

    .price-tag {
        background-color: #fff;
        margin: 0 15px;
        display: flex;
        height: 70px;
        max-height: 70px;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .tag-label {
            font-size: .875rem;
            font-weight: bold;
        }

        .tag-price {
            font-size: 2rem;
            font-weight: bold;
            line-height: 1;
            position: relative;
            display: block;
            text-align: center;
        }
    }
}
