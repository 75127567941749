.discount-overview {
    min-height: 3.8rem;
    background-color: $color-orange-tint;
    border-bottom-left-radius: 17px;
    color: #000;
    font-size: .82rem;

    .child {
        display: flex;
        justify-content: space-between;
        padding: 6px 15px 0;
        background-color: $color-orange;

        &:last-child {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
            border-bottom-left-radius: 17px;
            padding-bottom: 6px;
        }

        &.total {
            font-weight: bold;
        }
    }

    .discount-price {
        flex-basis: 60px;
        display: flex;
        justify-content: space-between;
    }
}
