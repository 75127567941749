/* Provider background */
.content-default {
    background: $color-topdeals;

    &.highlight {
        background: rgba($color-topdeals, .1);
    }
}

.content-t-mobile {
    background: $color-tmobile;

    &.highlight {
        background: rgba($color-tmobile, .1);
    }
}

.content-odido {
    background: $color-odido;

    &.highlight {
        background: rgba($color-odido, .1);
    }
}

.content-tele2 {
    background: $color-tele2;

    &.highlight {
        background: rgba($color-tele2, .1);
    }
}

.content-telfort {
    background: $color-telfort;

    &.highlight {
        background: rgba($color-telfort, .1);
    }
}

.content-ben {
    background: $color-ben;

    &.highlight {
        background: rgba($color-ben, .1);
    }
}

.content-kpn {
    background: $color-kpn;

    &.highlight {
        background: rgba($color-kpn, .1);
    }
}

.content-vodafone {
    background: $color-vodafone;

    &.highlight {
        background: rgba($color-vodafone, .1);
    }
}

.content-hollandsnieuwe {
    background: $color-hollandsnieuwe;

    &.highlight {
        background: rgba($color-hollandsnieuwe, .1);
    }
}

.content-lebara {
    background: $color-lebara;

    &.highlight {
        background: rgba($color-lebara, .1);
    }
}
/* Provider background */
.background-gradient-default {
    background: linear-gradient($color-topdeals 0%, #f1eff0 50%) !important;
}

.background-gradient-t-mobile {
    background: linear-gradient($color-tmobile 0%, #f1eff0 50%) !important;
}

.background-gradient-odido {
    background: linear-gradient($color-odido 0%, #f1eff0 50%) !important;
}

.background-gradient-tele2 {
    background: linear-gradient($color-tele2 0%, #f1eff0 50%) !important;
}

.background-gradient-telfort {
    background: linear-gradient($color-telfort 0%, #f1eff0 50%) !important;
}

.background-gradient-ben {
    background: linear-gradient($color-ben 0%, #f1eff0 50%) !important;
}

.background-gradient-kpn {
    background: linear-gradient($color-kpn 0%, #f1eff0 50%) !important;
}

.background-gradient-vodafone {
    background: linear-gradient($color-vodafone 0%, #f1eff0 50%) !important;
}

.background-gradient-hollandsnieuwe {
    background: linear-gradient($color-hollandsnieuwe 0%, #f1eff0 50%) !important;
}

.background-gradient-lebara {
    background: linear-gradient($color-lebara 0%, #f1eff0 50%) !important;
}

/* Provider text */
.text-default {
    color: $color-topdeals;
}

.text-t-mobile {
    color: $color-tmobile;
}

.text-odido {
    color: $color-odido;
}

.text-tele2 {
    color: $color-tele2;
}

.text-telfort {
    color: $color-telfort;
}

.text-ben {
    color: $color-ben;
}

.text-kpn {
    color: $color-kpn;
}

.text-vodafone {
    color: $color-vodafone;
}

.text-hollandsnieuwe {
    color: $color-hollandsnieuwe;
}

.text-lebara {
    color: $color-lebara;
}
/* Provider text */

/* Provider checkout button */
.btn-checkout-wrapper-t-mobile:not(:hover) {
    background: linear-gradient(pink, $color-tmobile);

    .btn-checkout {
        background: $color-tmobile;
    }
}

.btn-checkout-wrapper-odido:not(:hover) {
    background: linear-gradient(#E5E3FF, $color-odido);

    .btn-checkout {
        background: $color-odido;
    }
}

.btn-checkout-wrapper-tele2:not(:hover) {
    background: linear-gradient(lightgrey, $color-tele2);

    .btn-checkout {
        background: $color-tele2;
    }
}

.btn-checkout-wrapper-ben:not(:hover) {
    background: linear-gradient(#c8e5e4, $color-ben);

    .btn-checkout {
        background: $color-ben;
    }
}

.btn-checkout-wrapper-kpn:not(:hover) {
    background: linear-gradient(#a6dfa3, $color-kpn);

    .btn-checkout {
        background: $color-kpn;
    }
}

.btn-checkout-wrapper-vodafone:not(:hover) {
    background: linear-gradient(#fa757a, $color-vodafone);

    .btn-checkout {
        background: $color-vodafone;
    }
}

.btn-checkout-wrapper-lebara:not(:hover) {
    background: linear-gradient(#958dda, $color-lebara);

    .btn-checkout {
        background: $color-lebara;
    }
}

.btn-checkout-wrapper-hollandsnieuwe:not(:hover) {
    background: linear-gradient(#fab6ba, $color-hollandsnieuwe);

    .btn-checkout {
        background: $color-hollandsnieuwe;
    }
}

.btn-checkout-wrapper-default:not(:hover) {
    background: linear-gradient(rgb(184, 184, 184), $color-topdeals);

    .btn-checkout {
        background: $color-topdeals;
    }
}

[class*="btn-checkout-wrapper-"]:hover {
    &:not(.disabled) {
        background: linear-gradient(#ffefc3, #e4a600);

        .btn-checkout {
            background: #e4a600;
        }
    }

    &.disabled {
        background: linear-gradient(#00B2B266, $color-topdeals);
        pointer-events: none;

        .btn-checkout {
            background: $color-topdeals;
        }
    }
}

.content-los-toestel {
    background: #fff;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.los-toestel-model {
    display: none;
}

@media (min-width: 1400px) {
    .los-toestel-model {
        display: block;
        position: absolute;
        right: 8rem;
    }
}
/* Provider checkout button */
