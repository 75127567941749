$blue: #286090;


@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "~@vueform/multiselect/themes/default.css";

// Fonts
@import 'fonts';

@import 'normalize';
@import 'provider-helpers';
@import 'general';

@import 'pages/offer-list';
@import 'pages/orderpage';
@import 'pages/insurance';
@import 'pages/sitemap';
@import 'pages/offerspage';

@import 'modals/renewal-check';

@import 'components/scrollbar';
@import 'components/loader';
@import 'components/breadscrumbs';
@import 'components/header';
@import 'components/pricing-details';
@import 'components/footer';
@import 'components/device';
@import 'components/filter';
@import 'components/carousel';
@import 'components/provider';
@import 'components/product';
@import 'components/connection-speed';
@import 'components/discount-overview';
@import 'components/product-image-preview';
@import 'components/ilt-table';
@import 'components/contract-info-tabs';
@import 'components/client-order-form';
@import 'components/orderpage/contract-overview';
@import 'components/orderpage/cart';
@import 'components/device-discount-overview';
@import 'components/form-components';
