.breadcrumb {
    margin-bottom: .6rem;
    min-height: 21px;

    .breadcrumb-item {
        a {
            font-size: 0.875rem;
            font-weight: bold;
            color: #666;
        }

        &:hover a {
            text-decoration: underline;
            color: #555;
        }

        &.active {
            pointer-events: none;
            text-decoration: none;
        }
    }
}
