.form-select2 {
    width: 100%;

    .select2-container {
        width: 100% !important;
    }
}

.availability-in-stock {
    background-color: $availability-in-stock;
}

.availability-in-days {
    background-color: $availability-in-days;
}

.availability-ordered {
    background-color: $availability-ordered;
}

form {
    .invalid-feedback {
        display: block;
    }
}

.cursor-pointer {
    cursor: pointer;
}

/*
* Hide Arrows From Input Number
*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

button:focus, .btn:focus {
    outline: none;
    box-shadow: none;
}

.absolute-popup-close {
    position: absolute;
    right: 5px;
    top: 0;
}

.order-button {
    text-align: center;

    .btn-checkout-wrapper {
        padding: 5px;
        border-radius: 50px;
        box-shadow: 0 5px 10px rgb(0 0 0 / 60%);
        display: inline-block;

        .btn-checkout {
            color: #fff;
            font-size: 34px;
            font-weight: bold;
            border-radius: 50px;
            display: flex;
            padding: 0 5rem;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            cursor: pointer;
        }

        :hover i {
            transform: rotate(180deg);
        }
    }

    @media screen and (max-width: 767px) {
        .btn-checkout-wrapper {
            display: block;

            .btn-checkout {
                font-size: 30px;
                width: 100%;
                padding: 0 1rem;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .custom-main-container {
        padding-left: 0;
        padding-right: 0;
    }
}

.dropdown-link {
     &:hover {
         text-decoration: underline !important;
     }
}

.check-list {
    list-style: none;
    list-style-image: url('/images/checkmark.png');
}

.multiselect {
    .multiselect-dropdown {
        max-height: var(--ms-max-height, 22rem);
    }

    &.filter-multiselect {
        .multiselect-dropdown .multiselect-option {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.custom-main-container > div.vue-app {
    min-height: 173rem;
}
