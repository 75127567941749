$color-topdeals: #999999;
$color-tmobile: #e40174;
$color-tele2: #0d60b5;
$color-kpn: #1e9a15;
$color-ben: #1d9fe7;
$color-vodafone: #fb0018;
$color-lebara: #3c3696;
$color-hollandsnieuwe: #fb6668;
$color-telfort: #003886;
$color-odido: #8d88fc;

$telfort-bg-color: #CAE0FF;

$footer-bg-color: #6b6565;
$footer-part-2-bg-color: #d3d3d3;

$availability-in-stock: #7BCC43;
$availability-in-days: #ADD8E6;
$availability-ordered: #FFA500;

$color-orange: #FFC058;
$color-orange-shade: #F49600;
$color-orange-tint: #FBE9CB;

$color-grey-dark: #333;
$color-grey: #626262;
$color-grey-tint: #999;
$color-grey-light: #dedede;
$color-grey-white: #eee;

$color-holiday: #FF8A00;

$color-yellow: #FCF125;

$color-red: #e20311;

$gw-border-radius: 20px;
$gw-border-radius-lg: 30px;

$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");
$form-select-indicator: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");

$icon-plus: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iPjwvcGF0aD48ZyBmaWxsPSIjNGRhNWE3IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik03OC44MzMzMywxNC4zMzMzM3Y2NC41aC02NC41djE0LjMzMzMzaDY0LjV2NjQuNWgxNC4zMzMzM3YtNjQuNWg2NC41di0xNC4zMzMzM2gtNjQuNXYtNjQuNXoiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg==');
$icon-minus: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAALUlEQVRIiWNgGAWjYBSMAoKAEZnju3T5f2oYujk6Em4uEzUMHAWjYBSMAgoBACz6BAR5G58qAAAAAElFTkSuQmCC');

$sidebar-width: 300px;
