.col-search {
    position: relative;

    &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 4px;
        height: 100%;
        background-color: #000;
    }

    &::after {
        right: -2px;
    }

    &::before {
        left: -2px;
    }

    .custom-search-input {
        position: relative;
        border-radius: 20px;

        .custom-search-input-wrap {
            background-color: #fff;
            border-radius: 20px;

            &:hover {
                .query-search-input {
                    background-color: rgba(0, 128, 128, .2);
                }

                .query-search-btn {
                    color: teal;
                }
            }
        }

        .multiselect-search {
            border-color: #000;
            border-width: 1px;
            border-radius: 20px;
            padding-right: 4rem;
            background-color: #fff;
            color: #000;

            &:focus {
                border-color: #000;
                box-shadow: none;
            }
        }

        .query-search-btn {
            position: absolute;
            right: 7px;
            top: 10px;
            color: #f00;

            &:focus {
                outline: none;
            }
        }

        .multiselect-dropdown {
            border-radius: 4px;

            .multiselect-option {
                color: #1f2937;

                &.is-selected {
                    background: $color-topdeals;
                    color: #fff;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .col-search {
        &::after, &::before {
            display: none;
        }
    }
}
