.order-page-tab-content {
    margin-bottom: 1rem;
    padding: 15px 0 60px;
    border-bottom-right-radius: $gw-border-radius;

    .page-title {
        color: #fff;
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 15px;
    }

    .subtitle {
        color: #fff;
        font-size: 1.3rem;
    }

    .provider-banner {
        margin-bottom: 20px;
    }

    .legend-title {
        margin-bottom: 20px;
        font-size: 1.313rem;
        line-height: inherit;
        color: #333;
        border: 0;
        border-bottom: 1px solid #e5e5e5;
    }

    .right-tab-header {
        display: flex;
        align-items: stretch;
        font-weight: bold;
        font-size: 24px;
        border-bottom-left-radius: $gw-border-radius-lg;
        border-top-right-radius: $gw-border-radius-lg;
        background-color: #ccc;

        .header-item {
            display: flex;
            flex: 1;
            color: #999;
            border-top-right-radius: $gw-border-radius-lg;
            padding: 8px 15px 10px 10px;
            line-height: 1;

            .step-name {
                font-size: 24px;
                margin-bottom: 5px;
                text-transform: capitalize;
            }

            .step-description {
                font-size: 13px;
                font-weight: normal;
            }

            .step-number {
                margin-right: 5px;

                div {
                    background-color: #fff;
                    padding: 5px;
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    text-align: center;
                    line-height: .8;
                }
            }

            &.active {
                color: #fff;
                background-color: #000;
                border-bottom-left-radius: $gw-border-radius-lg;
                border-top-right-radius: $gw-border-radius-lg;

                .step-number div {
                    color: #000;
                }

                &:not(.single) {
                    border-bottom-right-radius: $gw-border-radius-lg;
                }
            }
        }
    }

    .right-tab-wrapper {
        background-color: #fff;
        border-top-right-radius: $gw-border-radius-lg;

        .right-tab-header {

        }

        .right-tab-content {
            padding: 1.2rem;
        }

    }

    @media screen and (max-width: 767px) {
        .right-tab-wrapper {
            .right-tab-content {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}
