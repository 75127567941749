.footer {
    padding-top: 1rem;
    background: $footer-bg-color;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: 10px;

    .footer-titles {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        ul {
            margin: 0;
            padding: 0;

            font-size: 12px;
            color: #fff;

            li {
                display: inline-block;

                &::after {
                    content: '';
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    border-radius: 4px;
                    margin: 0 4px 2px;
                    color: white;
                    background-color: white;
                }

                &:last-child::after {
                    display: none;
                }
            }
        }
    }

    .footer-part-2 {
        margin: 0;
        padding: 15px 0;
        border-top: 1px solid #d9d9d9;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        background: $footer-part-2-bg-color;

        .footer-text-box {
            line-height: 17px;
            color: #818181;
            width: 80%;
            margin: 0 auto 15px;
            text-align: center;
            padding: 0 15px;
        }

        .footer-links-wrapper {
            background: #fff;
            border-top-right-radius: 20px;
            border-bottom-left-radius: 20px;
            margin: 15px;
        }

        .footer-links {
            border-bottom-left-radius: 20px;

            .links-header {
                font-weight: bold;
                color: #fff;
                font-size: 13px;
                padding: 10px 15px;
                border-top-right-radius: 20px;
                background: $footer-bg-color;
            }

            .links-body {
                background: #fff;
                padding: 15px;

                > .row > * {
                    &:first-child ul,
                    &:last-child ul {
                        column-width: auto;
                        column-count: 2;
                    }
                }

                .links-middle {
                    border-left: 1px solid #666;
                    border-right: 1px solid #666;
                }
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                a {
                    color: #666;
                    font-size: 13px;
                    text-decoration: underline;
                }
            }

            .links-footer {
                display: flex;
                align-items: center;
                padding: 7px 20px;
                background: $footer-bg-color;
                border-bottom-left-radius: 20px;
            }

            .links-footer-text {
                background-color: white;
                padding: 15px 20px 20px;
            }

            .links-footer-title {
                color: #fff;
                font-size: 13px;
                font-weight: bold;
                padding-right: 45px;
            }
        }

        .under-footer-links {
            color: #666;
            font-weight: bold;
            font-size: 12px;
            margin: 0;
            line-height: 18px;
            padding: 15px;
        }

        .footer-rights {
            color: #818181;
            text-align: center;
            padding: 0 15px;

            .info-footer {
                margin-bottom: 0;
            }
        }
    }

    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (max-width: 1170px) {
    .footer {
        .footer-part-2 .footer-links {
            .links-body {
                > .row > * ul {
                    background: #fff;
                    padding: 15px;
                }

                .links-middle {
                    border: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .footer {
        .footer-titles {
            display: block;
            text-align: center;

            img {
                margin-bottom: 5px;
            }
        }

        .footer-part-2 {
            .footer-links {
                margin-bottom: 15px;

                .links-body {
                    padding: 0;
                    background: none;

                    ul {
                        margin-bottom: 15px;
                    }
                }
            }

            .footer-text-box {
                width: 100%;
                text-align: left;
            }

            .footer-links-wrapper {
                background: none;
            }

            .under-footer-links {
                background: #fff;
                border-bottom-left-radius: $gw-border-radius;
            }
        }
    }
}
