.product {
    background-color: #fff;
    border-bottom-right-radius: $gw-border-radius;
    margin-bottom: 20px;

    .product-header {
        background-color: #fff;
        border-bottom-left-radius: $gw-border-radius;
        width: 100%;

        &.filled {
            background-color: $gw-border-radius;
        }

        .network {
            border-bottom-right-radius: $gw-border-radius;
            min-height: 3.125rem;
            width: 100%;

            .selected-net {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 3.125rem;
                border-bottom-right-radius: $gw-border-radius;
                background-color: #fff;
                padding: 0 10px;

                img {
                    max-height: 2rem;
                }
            }

            .contract-duration {
                font-size: 0.813rem;
                font-weight: bold;
            }

            .non-sub-title {
                color: $color-topdeals;
                font-weight: bold;
                font-size: 1.25rem;
            }
        }
    }

    .product-preview {
        .product-title {
            overflow: hidden;
            font-size: 1.125rem;
            font-family: Arial, Helvetica, sans-serif;
            color: #666;
            text-align: center;
            padding: 0 .5rem;
        }

        @media screen and (min-width: 768px) {
            .product-title {
                height: 3.125rem;
            }
        }

        &.simonly {
            padding-bottom: 0;

            .product-title {
                height: auto;
                font-size: 1.25rem;
                font-weight: 600;
            }
        }
    }

    .offer-preview {
        .delivery {
            padding: 0.438rem;
            font-size: 0.813rem;
            color: #fff;
            font-weight: bold;
            font-family: Arial, Helvetica, sans-serif;
            text-align: center;
        }

        .offer-details {
            border-top-right-radius: $gw-border-radius;
            border-bottom-left-radius: $gw-border-radius;
        }

        .under-line-color {
            background-color: #d2d2d2;
            border-bottom-left-radius: $gw-border-radius;
            border-top-right-radius: $gw-border-radius;

            .under-last {
                border-bottom-left-radius:$gw-border-radius;
                border-top-right-radius: $gw-border-radius;
            }

            .offer {
                display: flex;
                justify-content: center;
                padding: 10px 20px 10px;
                position: relative;

                &.single-offer, .subscription {
                    font-weight: bold;
                    color: #fff;
                }

                &.single-offer {
                    flex-direction: column;
                    align-items: center;
                    background-color: #fff;
                    color: #000;
                    font-size: 0.938rem;
                }

                .bundle-text {
                    font-size: 0.813rem;
                    font-style: italic;
                    max-width: 100%;
                    height: 1.25rem;
                }
            }
        }

        .price {
            background-color: #fff;
            border-top-right-radius: $gw-border-radius;
            border-bottom-left-radius: $gw-border-radius;
            display: flex;
            justify-content: center;
            font-family: Arial, Helvetica, sans-serif;

            > * {
                flex: 100%;
                display: flex;
                justify-content: space-between;
                padding: 0 10px;
                color: #000;

                &:first-child {
                    background-color: #d2d2d2;
                }

                &:last-child:not([class*="content-"]) {
                    background-color: $color-grey;
                    color: $color-yellow;
                    border-bottom-right-radius: $gw-border-radius;
                }

                .price-amount {
                    flex: 1;
                    text-align: center;
                    padding: 6px 0;
                    font-weight: bold;
                }

                .price-text {
                    font-size: 0.75rem;
                    line-height: 1;
                    margin-right: 5px;
                }

                .price-value {
                    font-weight: bold;
                    font-size: 1.438rem;
                }
            }

            &.simonly > * {
                &:last-child:not([class*="content-"]) {
                    border-bottom-right-radius: 0;

                    .price-amount {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .extra-product-details {
        padding: 10px;
        text-align: center;

        a {
            background-color: #fff;
            color: #000;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: .75rem;
            font-weight: bold;
            text-decoration: underline;
            text-transform: uppercase;
        }
    }

    .btn-order-wrap {
        width: 80%;
        margin: 0 auto;
        padding: 20px 0;

        .btn-order {
            box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.53);
            padding: 10px 5px;
            text-align: center;
            border-radius: 5px;

            a {
                text-align: center;
                text-decoration: none;
                display: block;
                color: white;
                font-size: 1.438rem;
                font-family: Arial, Helvetica, sans-serif;
                line-height: 1.5rem;
                cursor: pointer;
                letter-spacing: .09rem;
            }

            &:not([class*='content-']) {
                background-color: $color-grey;
            }
        }
    }
}
