.sitemap-section {
    a {
        color: #337ab7;
    }

    .section-title {
        font-size: 1.5rem;
        background-color: #eeeeee;
        padding: .5rem 1rem;
    }

    .section-subtitle {
        font-size: 1.1rem;
        background-color: #eeeeee;
        padding: .3rem 1rem;
    }

    .sub-section {
        margin-bottom: 2rem;
        padding: 0 .5rem;
    }

    .device-list {
        display: block;
        column-count: 2;
        column-gap: 1rem;

        .list-group-item:last-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        }
    }
}
