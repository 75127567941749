.form-check {
    .form-check-input:not(.insurance-checkbox) {
        &[type=radio] {
            border: 2px solid rgb(128, 128, 128) !important;
            height: 18px;
            width: 18px;
        }

        &:checked {
            &[type=radio] {
                background-image: none !important;
                background-color: #f9d265 !important;
                height: 18px;
                width: 18px;
                border: 2px solid rgb(128, 128, 128) !important;
            }
        }
    }
}

.multiselect {
    .multiselect-option-custom {
        overflow: hidden;
        max-width: 100%;
    }

    .is-pointed {
        background: #286090 !important;
        color: #fff !important;
    }

    .is-selected {
        background: #286090 !important;
        color: #fff !important;
    }

}

.los-toestel-form {
    background: #ffcc66;
    border: 2px solid #a6a6a6;
    font-size: 0.875rem;
}

.col-item-reset-filters {
    width: 20%;
    padding-right: 0.3rem;
}

.btn-reset-filters {
    background: #666666;
    color: #ddb466;
    font-weight: bold;
    padding: 0.33rem 1.6rem;
}

.btn-reset-filters:hover {
    color: #ddb466;
}

@media (min-width: 763px) {
    input.los-toestel-form {
        width: 75%;
    }

    .btn-reset-filters {
        margin-right: 1rem;
        float: right;
    }
}
