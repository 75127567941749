.header-top-banner {
    position: relative;
    font-family: DIN-black Medium, sans-serif;

    .phone-image-logo {
        position: absolute;
        z-index: 9999;
        top: -25px;
        left: 10px;
    }

    .insurance-image-logo {
        position: absolute;
        right: 19px;
        top: -7px;
        z-index: 9999;

        img {
            width: 125px;
            height: 100%;
        }
    }

    .starting-from {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 9999;
        color: white;
        background-color: red;
        right: 25px;
        bottom: -15px;
        border-radius: 50%;
        padding: 10px;
        width: 110px;
        height: 110px;
        -webkit-box-shadow: -15px 15px 5px 2px rgba(0,0,0,0.59);
        -moz-box-shadow: -15px 15px 5px 2px rgba(0,0,0,0.59);
        box-shadow: -15px 15px 5px 2px rgba(0,0,0,0.59);

        .price-text {
            font-size: 11px;
            font-weight: bold;
        }

        .price {
            display: flex;
            position: relative;
            font-weight: bolder;
            height: 40px;
            font-family: 'Noto Sans', sans-serif;

            .price-value-1 {
                position: absolute;
                font-size: 55px;
                right: 0;
                top: -18px;
            }

            .price-value-2 {
                position: absolute;
                font-size: 35px;
                right: -37px;
                top: -9px;
            }

            .price-value-3 {
                position: absolute;
                font-size: 55px;
                right: -10px;
                top: -20px;
            }
        }
    }

    .header-title-1 {
        display: flex;
        flex-direction: column;
        color: white;
        background: rgb(0,0,1);
        background: linear-gradient(180deg, rgba(0,0,1,1) 10%, rgba(0,69,78,1) 100%);
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;

        .header-list {
            padding: 20px 0 5px 26px;

            .list-item-wrapper{
                position: relative;
            }

            .header-list-check {
                position: absolute;
            }

            .list-item {
                padding-left: 20px;
                font-family: DIN-light Light, sans-serif;
            }
        }

        .header-title {
            font-size: 38px;
            text-align: center;
            padding-top: 5px;
            padding-left: 50px;
        }

        .header-description-1 {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 15px;
            padding-right: .1rem;

            &:first-child {
                margin-bottom: 5px;
            }
        }

        .header-description-2 {
            font-size: .85rem;
            margin-bottom: 10px;
            padding-right: 40px;
            font-family: DIN-light Light, sans-serif;
        }
    }
}

@media screen and (max-width: 1200px) {
    .header-top-banner {
        .starting-from {
            right: -10px;
        }

        .phone-image-logo {
            top: 65px;
            left: -30px;

            img {
                width: 210px;
                height: 238px;
            }
        }

        .insurance-image-logo {
            right: 0;
            left: 15px;
        }

        .header-title-1 {
            .header-title {
                font-size: 35px;
            }

            .header-description-1 {
                font-size: 18px;
            }

            .header-list {
                padding: 20px 0 5px 0;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .header-top-banner {
        .starting-from {
            right: 10px;
        }

        .phone-image-logo {
            top: 100px;
            left: 0;

            img {
                width: 160px;
                height: 188px;
            }
        }

        .header-title-1 {
            .header-description-1 {
                padding: 0 25%;
            }

            .header-list {
                padding: 20px 0 5px 50px;
            }

            .header-title {
                font-size: 30px;
                padding: 5px 15%;
            }
        }

    }
}

@media screen and (max-width: 425px) {
    .header-top-banner {
        .starting-from {
            transform: scale(0.9);
        }

        .insurance-image-logo {
            img {
                transform: scale(0.9);
            }
        }
        .header-title-1 {
            .header-title {
                font-size: 18px;
                padding-left: 110px;
                padding-right: 50px;
            }

            .header-description-1 {
                font-family: DIN-light Light, sans-serif;
                font-size: 14px;
                padding: 0 10% 0 40%;
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .header-top-banner {
        .phone-image-logo {
            img {
                width: 140px;
                height: 168px;
            }
        }

        .starting-from {
            right: 3px;
        }

        .header-title-1 {
            .header-title {
                font-size: 16px;
                padding-left: 85px;
                padding-right: 20px;
            }

            .header-description-1 {
                padding: 0 10% 0 35%;
            }

            .header-list {
                font-size: 13px;
                padding: 10px 0 20px 30px;
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .header-top-banner {
        .phone-image-logo {
            top: 93px;

            img {
                width: 120px;
                height: 148px;
            }
        }

        .insurance-image-logo {
            left: -7px;
            top: -1px;

            img {
                transform: scale(1);
                width: 100px;
                height: 100%;
            }
        }

        .starting-from {
            transform: scale(0.7);
            right: -8px;
            bottom: -35px;
        }

        .header-title-1 {
            .header-title {
                padding-left: 53px;
            }

            .header-list {
                padding: 10px 0 20px 23px;
            }
        }
    }
}

.radio-section-insurance-content-wrapper {
    display: flex;
    padding: 0 !important;
    overflow: hidden;
    color: #000;
    font-family: DIN-light Light, sans-serif;

    .radio-wrapper-1, .radio-wrapper-2 {
        width: 100%;
        padding: 15px;
    }

    .radio-wrapper-2 {
        border-left: 4px solid #f0eeec;
    }

    .radio-section-offer {
        font-weight: bold;
        font-size: 20px;
        padding: 5px 10px;
    }

    .radio-section-buttons {
        display: flex;
        margin-bottom: 5px;
        padding: 0 .5rem;

        .checkbox-insurance {
            flex-grow: 4;
            margin: 0;

            .insurance-check-label {
                padding-left: 16px;
                vertical-align: sub;
            }
        }

        .insurance-price {
            flex-grow: 1;
            align-self: center;
            font-weight: 600;
            font-size: 25px;
            white-space: nowrap;
        }
    }

    .form-check {
        display: flex;

        .form-check-input {
            min-width: 1.75rem;
            width: 1.75rem;
            height: 1.75rem;
            border: 2px solid #dedbd8;

            &.insurance-checkbox {
                background-color: transparent;
                border-radius: 0.25em;
                border-color: #fff;
            }

            &:checked {
                &[type=checkbox], &.insurance-checkbox {
                    background-image: url("/images/checkmark-insurance.png");
                    background-size: 1.15rem;
                }

                &.insurance-checkbox {
                    background-color: transparent;
                    border-color: #fff;
                }

                &[type="checkbox"]:not(.insurance-checkbox) {
                    background-color: #fff;
                    border-color: #dedbd8;
                }
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }

            & + .form-check-label {
                padding-left: .5rem;
            }
        }
    }
}

.select-section-content-wrapper {
    padding: 10px 15px;
    border: 7px solid #f0eeec;
    background-color: #dedbd8;
    border-radius: 18px;
    -webkit-box-shadow: inset 0 5px 5px 0 #aaac, 0 5px 5px #aaac;
    -moz-box-shadow: inset 0 5px 5px 0 #aaac, 0 5px 5px #aaac;
    box-shadow: inset 0 5px 5px 0 #aaac, 0 5px 5px #aaac;

    p {
        padding-left: 10px;
        font-weight: bold;
    }
}

.insurance-page-content-wrapper {
    border: 2px solid #00454e;
    border-top: 0;
    margin-top: -20px;
    border-bottom-left-radius: 25px;
    font-family: DIN-light Light, sans-serif;

    a {
        color: #337ab7;
        text-decoration: none;
    }

    .dekking-list {
        font-family: DIN-light Light, sans-serif;
        padding: 20px;

        table {
            font-size: 18px;

            thead {
                color: #7D408E;
            }

            thead tr th, tbody tr td {
                border: none;
            }

            thead tr th {
                padding: 10px 8px;
            }

            tbody tr td {
                padding: 4px 8px;
            }

            tbody tr td:last-child {
                border-left: 2px solid #000;
            }
        };

        .insurance-coverage-wrap {
            position: relative;
            text-align: center;

            .insurance-coverage {
                position: relative;
                display: inline-block;
                height: 21px;

                &.checkmarks {
                    display: inline-block;
                    transform: rotate(45deg);
                    height: 21px;
                    width: 10px;
                    border-bottom: 2px solid #7D408E;
                    border-right: 2px solid #7D408E;
                }

                &.close-mark {
                    width: 21px;

                    &:after, &:before {
                        content: '';
                        position: absolute;
                        left: 9px;
                        height: 21px;
                        width: .145rem;
                        background-color: #000;
                    }

                    &:before {
                        transform: rotate(45deg);
                    }
                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    .general-selection-section-wrapper {
        padding-top: 55px;
        padding-bottom: 25px;
        border-bottom-right-radius: 25px;
        background-color: #dedbd8;

        .select-section-wrapper {
            padding: 0 25px;
            color: #666666;

            .select-section-title {
                font-size: 25px;
                font-weight: 900;
                padding-left: 7px;
                margin-bottom: 10px;
                font-family: DIN-black Medium, sans-serif;
            }

            .radio-section-title {
                font-size: 18px;
                font-weight: 600;
                padding-top: 10px;
                margin-bottom: 10px;
            }
        }

        .insurance-price-block {
            background-color: #d5eaea;
            border-radius: 5px;
            text-align: center;
            margin: 15px 25px 0;
            padding: 15px 0;
            font-size: 16px;

            .insurance-total-price-wrapper {
                display: flex;
                justify-content: center;
                font-family: 'Open Sans', Helvetica, Arial, sans-serif;
            }
        }

    }

    .enabling-wrapper {
        border: 2px solid #dedbd8;
        left: 0;
        top: -6px;
    }

    .enable-check-label {
        color: #999999;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 13px;
        padding-left: 35px;
    }

    .insurance-checkbox:checked ~ .checkmark-insurance-wrapper .checkmark-insurance {
        display: block;
    }

    .input-fields {
        padding: 45px;

        .form-details-title {
            font-family: DIN-bold Bold, sans-serif;
            font-size: 30px;
            font-weight: bold;
            color: #666666;
            margin-bottom: 15px;
            line-height: 1.1;
        }

        .tussenvoegsel-input {
            width: 50%;
        }

        .zip-street-ext-wrapper {
            display: flex;

            .zip-unit {
                padding-right:15px;
                flex:1;
            }

            .street-ext-wrapper {
                display:flex;
                flex:2;

                .street-unit {
                    padding-right:15px;
                    flex:1;
                }

                .ext-unit {
                    flex:1;
                }
            }
        }

        .relative-input-wrapper {
            position: relative;

            .company-name-tooltip {
                position: absolute;
                right: 8px;
                top: 8px;
            }
        }

        .phone-bill-wrapper {
            position: relative;
            display:flex;
            justify-content: space-between;
            align-items: center;

            .file-button {
                position: absolute;
                right: 8px;
                top: 6px;
                height: calc(100% - 6px * 2);
                width: 130px;
                display:flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                font-weight: 600;
                padding: 2px;
                background-color: teal;
                color: #fff;
                font-family: Arial, 'Open Sans', Helvetica, sans-serif;
                outline: 0;
                overflow: hidden;
                border-radius: 4px;
                z-index: 1;
                cursor: pointer;

                input[type="file"] {
                    display: block;
                    bottom: -1px;
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    z-index: 0;
                }
            }

            .file-input {
                cursor: default;
                background-color: #fff;
            }
        }

        .joined-inputs {
            &:first-child {
                padding-right: 0;
            }

            &:last-child {
                padding-left: 0;
            }
        }
        .date-input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
        }

        .phone-value-input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .insurance-form-checkout{
            .insurance-order {
                padding: 10px 10% 35px;

                .order-grad {
                    .content-insurance {
                        background: var(--topdeals-color);
                    }

                    .insurance-order-button {
                        font-size: 25px !important;
                    }
                }
            }

            .order .order-grad.content-insurance {
                background: linear-gradient(lightgrey, var(--topdeals-color));
            }

        }

        .incasso-insurance {
            color: #999999;
            margin-top: 0;
            margin-bottom: 15px;
            text-align: center;
            font-size: 16px;
        }
    }

    .insurance-type-description-wrapper {
        background-color: #f0eeeb;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;
        color: #333333;
        margin-bottom: 15px;

        .insurance-type-title {
            font-family: DIN-black Medium, sans-serif;
            font-size: 30px;
            font-weight: bold;
            color: #333333;
        }

        .excellent-block-wrapper {
            padding: 30px 70px 30px 20px;
        }

        .basis-block-wrapper {
            padding: 30px 40px 30px 50px;
        }

        .insurance-type-description-content {
            font-size: 14px;
        }
    }

    .insurance-info-section-wrapper {
        padding: 0 50px;
        color: #333333;
        margin-bottom: 20px;

        .insurance-info-title {
            font-family: DIN-black Medium, sans-serif;
            font-size: 30px;
            font-weight: bold;
            color: #333333;
        }

        .insurance-info-content {
            font-size: 14px;
        }
    }

    .insurance-report-section-wrapper {
        padding: 0 50px;
        margin-bottom: 20px;

        .insurance-report-title {
            font-family: DIN-black Medium, sans-serif;
            font-size: 30px;
            font-weight: bold;
            color: #333333;
        }

        .insurance-report-content {
            font-size: 14px;

            .qsx-title {
                font-family: DIN-black Medium, sans-serif;
            }
        }

        .report-insurance-button-wrapper {
            display: flex;
            justify-content: center;

            .report-insurance-button {
                color: white;
                font-weight: bold;
                font-size: 16px;
                text-decoration: none;
                background-color: #f9ac2b;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 220px;
                height: 40px;
                border-radius: 20px;

                &:hover {
                    color:white;
                    text-decoration:none;
                    cursor:pointer;
                }
            }
        }
    }

    .insurance-paragraph-separator {
        padding-bottom: 15px;
    }

    .enable-button-insurance {
        color: white;
        background-color: var(--topdeals-color);
        border: 0;
        border-radius: 20px;
        width: 100%;
        font-size: 26px;
        font-weight: bold;
        font-family: DIN-black Medium, sans-serif;
    }

    div > span.error-view {
        padding-left: 3px;
        color: rgba(255, 0, 0, .52);
    }

    #gender-error {
        padding-left: 18px;
    }

    .response-message-wrapper {

        .unit {
            margin-top: 15px;
            border: 2px solid;
            border-radius: 3px;
            display: block;
            font: 16px / 24px 'Open Sans', Helvetica, Arial, sans-serif;
            padding: 15px;
        }

        .success-message {
            text-align: center;
            background: #e8f5e9;
            border-color: #1b5e20;
            color: #1b5e20;

            .fa-check {
                color: #1b5e20;
            }
        }

        .error-message {
            text-align: center;
            background: #ffebee;
            border-color: #b71c1c;
            color: #b71c1c;

            .fa-check {
                color: #b71c1c;
            }
        }
    }
}

.insurance-crumbs {
    margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
    .select-section-content-wrapper {
        display: block;
        height: 100%;
        line-height: 1.1;

        .basis-insurance-wrapper {
            display: flex;
            background-color: #014751;
        }

        .excellent-insurance-wrapper {
            display: flex;
            background-color: #f9ac2b;
        }

        .insurance-type-text-wrapper {
            display: block;
            flex: 3;

            .radio-section-offer {
                padding: 5px 10px;
            }

            .insurance-price {
                padding-left: 15px;
                font-size: 38px;

                .value {
                    font-weight: 900;
                }
            }
        }

        .insurance-type-checkbox-wrapper {
            display: flex;
            flex:1;
            flex-direction: column;
            align-items: flex-end;
        }

        .insurance-type-title {
            padding: 15px 15px 5px 15px;
            font-size: 18px;
        }

        .insurance-check-label {
            padding-right: 15px;
        }

        .checkmark-insurance-wrapper {
            position: relative;
            left: 0;
            width: 50px;
            height: 50px;
        }

        .insurance-checkbox-label {
            padding: 15px 15px 0 0;
            font-size: 20px;
            text-align: right;
        }

        .checkbox-insurance {
            display: flex;
            justify-content: space-between;
            padding-right: 15px;

            .checkmark-insurance-wrapper {
                position: relative;
                width: 60px;
                height: 60px;
                left: 0;

                img {
                    width: 30px;
                    height: 30px;
                }
            }

            .insurance-checkbox:checked ~ .checkmark-insurance-wrapper .checkmark-insurance {
                display: block;
            }
        }
    }

    .radio-section-insurance-content-wrapper {
        .radio-wrapper-2 {
            border-left: none;
            border-top: 4px solid #f0eeec;
        }
    }

    .insurance-crumbs {
        margin-bottom: 0;
    }

    .insurance-page-content-wrapper {
        border: 0;

        .input-fields {
            padding: 15px 0;

            .phone-bill-wrapper {
                .file-button {
                    width: 95px;
                }
            }

            .tussen-input {
                padding-right: 2px;
            }

            .form-details-title {
                padding-left: 15px;
            }

            .zip-street-ext-wrapper {
                display: block;

                .zip-unit {
                    padding-right: 2px;
                    margin-bottom: 15px;
                    width: 50%;
                }

                .street-ext-wrapper {
                    .street-unit {
                        padding-right: 0;
                    }

                    .ext-unit {
                        padding-left: 5px;
                    }
                }
            }

            .insurance-form-checkout {
                .insurance-order {
                    padding: 10px 0 35px;
                }
            }

            .enable-check-label {
                font-size: 16px;
                padding-left: 55px;

                .enabling-wrapper {
                    top: -3px;
                    width: 45px;
                    height: 45px;

                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
            }

            .incasso-insurance {
                text-align: left;
                padding-left: 55px;
                font-size: 14px;
            }
        }

        .general-selection-section-wrapper {
            .select-section-wrapper {
                padding: 0;

                .radio-section-title {
                    font-size: 16px;
                    padding-top: 15px;
                    padding-left: 10px;
                }
            }
        }

        .insurance-type-description-wrapper {
            .basis-block-wrapper, .excellent-block-wrapper {
                padding: 15px;

                .insurance-type-title {
                    font-size: 27px;
                    color: #686b6b;
                }
            }

            .insurance-type-description-content {
                font-size: 15px;
            }
        }

        .insurance-info-section-wrapper {
            padding: 0;

            .insurance-info-title {
                font-size: 27px;
                color: #686b6b;
            }

            .insurance-info-content {
                font-size: 15px;
            }
        }

        .insurance-report-section-wrapper {
            padding: 0 15px;

            .insurance-report-title {
                font-size: 27px;
                color: #686b6b;
            }

            .insurance-report-content {
                font-size: 15px;
            }

            .report-phone-number {
                padding: 15px;
                font-size: 18px;
                color: #686b6b;

                a {
                    color:inherit;
                }
            }
        }

        .dekking-list {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

select.form-control.insurance-select {
    background: white;
}

.insurance_coveryou {
    margin-top:2000px;
    min-height: 400px;
    margin-bottom: 25px;
    font-size: 12px;

    .breadcrumb {
        color: #CCCCCC;
        margin-bottom: 10px;
    }

    .device_block {
        margin-top: 10px;
        border-top-right-radius: 18px;
        border-bottom-left-radius: 18px;
        border-width: 2px;
        border-style: solid;
        border-color: #00454e;
        margin-bottom: 10px;

        .logo {
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
            background-color: #008080;
            font-size: 18px;
            color: #fff;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 20px;
        }

        .content {
            padding-top: 10px;
            padding-bottom: 10px;
            overflow: visible;
            font-size: 12px;

            .wrapper {
                position: relative;
                background-color: transparent;

                .header {
                    @media all and (max-width:1280px) {
                        .phonebroke, .roodscherm {
                            display: none
                        }
                    }
                }

                .content {
                    .mod-cell {
                        .j-row {
                            &:last-child {
                                margin-bottom: 10px;
                            }

                            .icon-left {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .label {
                                white-space: normal;
                                height: auto;
                            }
                        }

                        .unit {
                            .checkbox {
                                display: flex;
                                align-items: center;
                                height: auto;
                                position: relative;

                                i {
                                    top: auto;
                                }
                            }

                            .incasso {
                                margin-top: 0;
                                margin-bottom: 15px;
                            }

                            .primary-btn {
                                margin-left: 0;
                                white-space: normal;
                            }
                        }
                    }
                }
            }
        }
    }
}
