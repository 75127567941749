.contract-overview {
    background-color: unset;

    .product-preview {
        padding: 0;

        .product-details-wrap {
            background-color: #fff;
            padding-right: 40px;
            border-radius: $gw-border-radius 0;
            z-index: 2;
        }

        .subscription-type {
            position: relative;
            background-color: #000;
            color: #fff;
            padding: 25px 30px;
            border-bottom-right-radius: $gw-border-radius;
            font-size: 1.2rem;
            z-index: 1;

            &::before {
                content: '';
                position: absolute;
                background-color: #000;
                width: 20px;
                height: 20px;
                right: 0;
                top: -20px;
                z-index: 0;
            }

            .logo {
                font-size: 1.5rem;
                font-weight: 600;
            }
        }

        .discount-overview {
            position: relative;
            min-height: auto;
            border-radius: 0;

            &::before {
                content: '';
                position: absolute;
                background-color: #FFC058;
                width: 20px;
                height: 20px;
                right: 0;
                top: -20px;
                z-index: 0;
            }

            .child:last-child {
                border-radius: 0;
            }
        }
    }

    .offer-preview {
        margin-bottom: 10px;

        .delivery-wrapper {
            background-color: #1a9eea;

            .delivery-text {
                font-size: 1.5rem;
                padding: 10px;
                font-weight: 600;
                text-align: center;
                border-bottom-left-radius: $gw-border-radius;
                color: #fff;
            }

            .delivery {
                padding: 20px;
                font-weight: 400;
                font-size: .9rem;
            }
        }

        .price {
            background-color: unset;

            > * {
                padding: 0;
            }

            .price-amount {
                padding: 0;

                .price-text {
                    font-size: 1.25rem;
                    padding: 10px;
                    background-color: #626262;
                    margin: 0;
                    color: #fff;
                }

                .price-value {
                    padding: 8px 10px;
                    font-size: 2.125rem;
                    color: #626262;
                }
            }

            &:not(.simonly) > *:first-child {
                border-bottom-right-radius: $gw-border-radius;

                .price-amount {
                    background-color: #626262;
                    border-bottom-right-radius: $gw-border-radius;

                    .price-text, .price-value {
                        border-bottom-right-radius: $gw-border-radius;
                    }

                    .price-value {
                        background-color: #ccc;
                    }
                }
            }

            & > *:last-child {
                border-bottom-left-radius: $gw-border-radius;

                .price-amount {
                    background-color: #ccc;
                    border-bottom-left-radius: $gw-border-radius;
                }

                .price-text, .price-value {
                    border-bottom-left-radius: $gw-border-radius;
                }

                .price-text {
                    color: #626262;
                    background-color: #ccc;
                }

                .price-value {
                    color: #fddd03;
                }
            }

            &.simonly > *:last-child {
                .price-amount {
                    display: block !important;
                    line-height: 1.2;

                    .price-text-inline {
                        font-size: 1.25rem;
                        color: #fff;
                    }
                }
            }
        }
    }

    .card {
        margin-bottom: 20px;
        background-color: #fff;
        border-radius: 20px 0;
        border: none;
        font-size: 1.125rem;

        &.no-background {
            background-color: transparent;
            color: #fff;
            font-weight: 600;
        }

        .card-header {
            padding: 30px 20px 0 20px;
            background-color: transparent;
            border-bottom: 0;

            .card-title {
                font-size: 1.5rem;
                font-weight: 600;
            }

            .card-link {
                text-decoration: underline;
                font-size: .875rem;
            }
        }

        .card-subtitle {
            font-size: .875rem;
            text-align: center;
            margin-bottom: 1.5rem;
        }

        .card-body {
            padding: 10px 20px 20px;

            .table {
                td:last-child {
                    text-align: right;
                }

                td:not(:first-child) {
                    font-size: 1rem;
                }

                td:not(.name) {
                    white-space: nowrap;
                }

                td.quantity {
                    font-size: 1rem;
                }
            }
        }

        .card-note {
            font-style: italic;
            font-size: .875rem;
        }
    }

    .ilt-table-content .ilt-table .table {
        td {
            white-space: nowrap;
        }

        th {
            white-space: break-spaces;
            vertical-align: top;
        }

        td, th {
            font-size: .75rem;
            word-break: normal;

            &:first-child {
                width: auto;
            }
        }
    }

    &.order {
        .product-preview {
            .product-details-wrap {
                padding: 2rem 0;
                border-radius: 0;
            }

            .subscription-type {
                border-top-left-radius: $gw-border-radius;
                padding-top: 5rem;
            }
        }

        .shipping-costs {
            color: $color-topdeals;
        }
    }

    &.device-contract-overview {
        .offer-preview .price > *:first-child .price-amount {
            .price-value {
                background-color: $color-grey;
                border-bottom-right-radius: 0;
            }
        }

        .product-preview {
            background-color: #fff;
            border-top-left-radius: 20px;

            .subscription-type {
                padding-top: 10px;
                padding-bottom: 10px;
                border-bottom-right-radius: 20px;
                border-top-left-radius: 20px;

                &:before {
                    display: none;
                }
            }

            .product-details-wrap {
                position: relative;
                z-index: 1;
                border-radius: 0 0 $gw-border-radius 0;
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        .offer-preview {
            position: relative;
            border-bottom-right-radius: $gw-border-radius;

            &::before {
                content: '';
                position: absolute;
                background-color: $color-grey;
                width: 20px;
                height: 20px;
                right: 0;
                top: -20px;
                z-index: 0;
            }

            .price > *:first-child .price-amount .price-value  {
                padding: 0;
            }

            .delivery-wrapper {
                background-color: transparent;
            }

            .delivery-text {
                font-size: 1rem;
                font-weight: 400;
                padding: 10px;
                border-bottom-right-radius: $gw-border-radius;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .card {
            .card-header, .card-body {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}
