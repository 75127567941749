.product-preview {
    padding: 0 0 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > * {
        width: 100%;
    }

    .product-details-wrap {
        display: flex;
        align-items: center;
        padding: 15px 0;

        > * {
            flex: 1;
        }
    }

    .product-image {
        padding: 0 5px;

        a {
            position: relative;
            display: block;
            height: 100%;

            .cashback {
                position: absolute;
                color: #fff;
                font-weight: 800;
                right: calc(50% - 58px);
                top: 44px;
                font-size: 1.563rem;
                line-height: .9;
            }

            .product-display {
                width: 100%;
                height: 11rem;
                object-fit: contain;

                &.single {
                    padding: 20px 0;
                }
            }
        }

        &.single {
            a {
                .cashback {
                    right: calc(50% - 62px);
                    top: 40px;
                }
            }
        }

        .cashback {
            position: absolute;
            color: #fff;
            font-weight: 800;
            right: calc(50% - 58px);
            top: 44px;
            font-size: 1.563rem;
            line-height: .9;
        }
    }

    .product-details {
        display: flex;
        flex-direction: column;

        .product-details-item {
            min-height: 4.688rem;
            padding: 10px;
            font-weight: bold;
            font-style: italic;
            font-size: 0.813rem;

            &:first-child {
                background-color: $color-grey-white;
                color: $color-grey-dark;
                margin-bottom: 5px;
                border-top-right-radius: $gw-border-radius;
            }

            &:last-child {
                background-color: $color-grey-dark;
                color: $color-grey-white;
                border-bottom-left-radius: $gw-border-radius;
            }

            &.box-hidden {
                visibility: hidden;
            }

            @media screen and (min-width: 768px) {
                &:first-child {
                    height: 6.125rem;
                }

                &:last-child {
                    height: 5rem;
                }
            }
        }
    }
}
