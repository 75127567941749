.client-order-form {
    font-size: .9rem;
    background-color: #fff;

    .form-group {
        margin-bottom: 1rem;
    }

    .btn-group {
        width: 100%;

        & > .btn {
            flex: 50%;
        }
    }
}
