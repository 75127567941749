.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;

    img {
        max-width: 100%;
        height: 3.2rem;
    }
}
