.top-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0 5px;

    .opening-hours-text {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #f7b000;
        flex-basis: 222px;
    }

    .top-menu-right {
        .navbar-toggle {
            border: none;
            margin: 0;
            float: none;
            padding: 0;
            background-color: transparent;
        }

        .top-menu-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .responsive-menu-icons {
                width: 100%;
            }
        }

        .top-menu-wrapper {
            .top-menu-menu {
                margin: 0;
                padding: 0 15px 0 0;
                list-style: none;
                display: flex;
                flex-wrap: wrap;

                > li {
                    display: inline-block;
                    padding: 5px 8px;
                    position: relative;

                    .single-dropdown {
                        position:absolute;
                        background-color: white;
                        display: none;
                        z-index: 1000;
                        width: 200px;
                        margin: 0;
                        padding: 10px;
                        line-height: 1.5;
                        box-shadow: 0 0 5px #ddd;
                        border-radius: 4px;

                        a {
                            color: inherit;
                        }
                    }

                    &:hover {
                        .single-dropdown {
                            display: block;
                        }
                    }

                    .double-dropdown {
                        position:absolute;
                        background-color: white;
                        display: none;
                        z-index: 1000;
                        min-width: 240px;
                        margin: 0;
                        padding: 10px;
                        line-height: 1.5;
                        box-shadow: 0 0 5px #ddd;
                        border-radius: 4px;

                        .toestel-verlengen {
                            margin-right: 15px;
                        }

                        a {
                            color: inherit;
                        }
                    }

                    &:hover {
                        .double-dropdown {
                            display: flex;
                        }
                    }

                    &:last-child:after {
                        display: none;
                    }

                    &:last-child,
                    &:last-child a {
                        padding-right: 0;

                        &:after {
                            display: none;
                        }
                    }

                    > a {
                        position: relative;
                        color: #494949;
                        font-size: 18px;
                        font-weight: bolder;
                        display: flex;
                        align-items: center;

                        &:after {
                            position: absolute;
                            content: '';
                            height: 60%;
                            border-right: 2px solid #f7b000;
                            display: inline-block;
                            right: -10px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1279px) {
    .top-menu {
        display: block;
    }
}

@media screen and (min-width: 768px) {
    .top-menu-wrapper.collapse:not(.show){
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .top-menu {
        padding: 10px 0 0 0;

        .top-menu-right {
            .top-menu-header {
            position: relative;
            display: flex;
            justify-content: space-around;
            margin-bottom: 0;
            }

            .top-menu-wrapper {
                .top-menu-menu {
                    margin-top: 10px;
                    flex-direction: column;
                    padding-bottom: 35px;
                    padding-right: 0;

                    li {
                        a {
                            &:after {
                                display: none;
                            }
                        }
                    }

                    li,
                    li a {
                        display: block;
                    }

                    li:first-child {
                        padding-left: 5px;
                    }

                    li:first-child a {
                        padding-left: 10px;
                    }

                    li:last-child {
                        padding-right: 5px;
                    }
                    li:last-child a {
                        padding-right: 10px;
                    }

                    > li:after {
                        display: none;
                    }

                    li.active {
                        background: #e7e7e7;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .top-menu{
        .top-menu-right {
            .top-menu-header {
                z-index: 1000;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 70px;
                border-radius: 100%;
            }
        }
    }
}

