.card.cart {
    position: sticky;
    top: 10px;
    z-index: 1000;
    border-radius: 0;
    margin: 0 0.75rem 1rem;

    .card-body {
        padding: 10px 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            padding: 0 5px;
        }
    };

    .cart-title {
        margin-bottom: 10px;
        margin-top: 0;
        font-size: .938rem;
        color: #000;
        font-weight: bold;
    }

    .cart-options {
        font-size: .75rem;
        color: #333;
        font-weight: 400;
    }

    .btn-checkout-mobile {
        color: #fff;
        font-weight: 600;
        background-color: $color-orange-shade;
        padding: 10px;
        font-size: .938rem;
    }
}
