@font-face {
    font-family: DIN-black Medium;
    src: url("./../fonts/din-black-regular.otf") format("opentype");
}

@font-face {
    font-family: DIN-light Light;
    src: url("./../fonts/din-light.otf") format("opentype");
}

@font-face {
    font-family: DIN-bold Bold;
    src: url("./../fonts/din-bold.otf") format("opentype");
}
