.device-list {
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.device-wrapper {
    padding: 1rem .5rem;
    border: 1px solid transparent;

    &:hover {
        border-color: #ccc;
        border-radius: 1rem 0 1rem 0;
    }

    .image {
        margin-bottom: 1.25rem;
        text-align: center;

        a {
            display: block;
        }

        img {
            height: 200px;
            width: 200px;
            object-fit: contain;
        }
    }

    .device-details-wrapper {

    }

    .device-details {
        font-weight: 600;
        margin-bottom: 2rem;
        text-align: center;
    }

    .device-delivery-time {
        text-align: center;
        padding: .25rem .625rem;
        font-size: .75rem;
        color: #000;
        font-weight: bold;

        &.in-stock {
            color: $availability-in-stock;
        }

        &.in-days {
            color: $availability-in-days;
        }

        &.ordered {
            color: $availability-ordered;
        }
    }

    .device-price-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .device-price {
        font-size: 1.25rem;
        font-weight: 600;
        color: #404040;
        position: relative;
    }

    .button-checkout {
        background-color: #005477;
        color: white;
        font-weight: 600;
        padding: .25rem .5rem;
        border-radius: 4px;
        transition: all 0.2s ease;
        font-size: .9rem;

        &:hover {
            color: #000;
            background-color: $warning;
        }
    }
}
