.ilt-table-wrap {
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: $gw-border-radius 0 $gw-border-radius 0;

    .triangle-radio {
        position: relative;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 28px 15px;
        border-color: transparent transparent #ccc transparent;
        margin: 0 auto;
        cursor: pointer;

        .inner-triangle {
            position: absolute;
            top: 7px;
            left: -10px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 20px 10px;
            border-color: transparent transparent #fff transparent;
            pointer-events: none;
        }

        &.active {
            border-color: transparent transparent $color-grey transparent;

            .inner-triangle {
                border-color: transparent transparent $color-yellow transparent;
            }
        }
    }
}

.ilt-table-content {
    background-color: #fff;
    border-bottom-right-radius: 20px;

    .ilt-table {
        direction: rtl;

        .table {
            direction: ltr;
            border-bottom: 2px solid #535353;
            margin-bottom: 0;
            font-weight: bold;
            font-size: 0.813rem;

            & > thead {
                background-color: $color-grey;
                z-index: 1;
                color: white;
                vertical-align: bottom;
            }

            & > tbody {
                vertical-align: revert;
            }

            th {
                padding: 10px;
                border-bottom: 2px solid #ddd;
            }

            td {
                padding: 8px 10px;
                color: #636363;
            }

            th:first-child, td:first-child {
                padding-left: 20px;
                width: 31%;
            }

        }
    }

    .under-table-footer-wrap {
        color: #636363;

        .under-table-footer {
            padding: 10px 20px;
            font-size: 0.813rem;
        }

        .arrow-wrap {
            text-align: center;
            padding: 5px 0;
        }

        &.show {
            .under-table-footer {
                height: auto;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .ilt-table-content {
        background-color: #fff;
        border-radius: $gw-border-radius 0 $gw-border-radius 0;
        padding-top: 20px;
        margin-bottom: 30px;

        .ilt-table {
            margin-bottom: 16px;

            .table thead {
                white-space: nowrap;
            }
        }

        .under-table-footer-wrap {
            .under-table-footer {
                height: 2.438rem;
                overflow: hidden;
                transition: height 0.35s ease;
                padding: 0 20px;
            }
        }
    }
}
