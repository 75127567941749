.renewal-check-modal {
    font-family: DIN-black Light, sans-serif;

    .modal-content {
        border-radius: 0 45px 0 45px;
    }

    .modal-header {
        font-family: DIN-black Medium, sans-serif;
        background-color: #E6CB60;
        border-bottom: none;

        .btn-close {
            box-sizing: border-box;
            width: 34px;
            height: 34px;
            padding: 6px;
            border: 3px solid #000;
            border-radius: 100%;
            opacity: .6;
            font-size: 15px;
            margin: 0;
        }

        .modal-title {
            font-size: 24px;
        }
    }

    .modal-body {
        background-color: #C5AD4C;
        padding: 0;
        position: relative;

        .information-section {
            background-color: #E6CB60;
            border-bottom-left-radius: 80px;
            color: #0f0f0f;
            padding: 2rem 1rem 2rem 2rem;

            >:first-child {
                font-weight: bold;
            }
        }

        .form {
            background-color: #FAE990;

            label {
                font-weight: 700;
                font-size: .9rem;
                display: block;
            }

            .form-control, .multiselect, .datepicker .dp__input {
                font-size: .875rem;
                padding: 6px 12px;
                color: #555;
                font-family: DIN-black Light, sans-serif;

                &:focus {
                    border-color: #f0ad4e;
                    box-shadow: 0 0 8px rgb(240 173 78 / 60%);
                }
            }

            .datepicker .dp__input {
                padding-left: 2rem;
            }
        }

        .client-type-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #0f0f0f;
            background-color: #C5AD4C;
            padding: 15px 25px;
            border-bottom-right-radius: 80px;
        }

        .form-content-wrap {
            padding: 1rem;
        }

        .loader {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            z-index: 100;
            background-color: rgba(255, 254, 237, .9);
        }
    }

    .modal-footer {
        background-color: #FAE990;
        border-top: none;

        .btn-renewal-check {
            min-width: 12.5rem;

            &:disabled {
                pointer-events: none;
            }
        }
    }
}
