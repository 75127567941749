.swiper-container {
    width: 100%;
    height: 100%;

    .swiper-slide {
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.homepage-banners {
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
