.menu-panel {
    position: relative;
    .top-panel {
        color: #fff;
        cursor: pointer;

        &:hover {
            .top-panel-header{
                background: #e30613;
            }
        }

        &.active {
            opacity: 0.5;
        }

        .top-panel-header {
            background: $color-grey;
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1;
            font-weight: bold;
            position: relative;
            min-height: 75px;

            .top-panel-header-title {
                font-size: 18px;
                margin-right: 5px;

                .small {
                    font-weight: normal;
                }

                .top-panel-header-link {
                    color: #fff;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .top-panel-header-icon {
                width: 32px;
                height: 32px;
            }

            .icon-expand {
                font-size: 2rem;
            }
        }

        .top-panel-footer {
            background: #000;
            text-align: center;
            padding: 10px 20px;
            position: relative;
            max-height: 54px;
            height: 100%;

            .top-panel-footer-link {
                color: #fff;
                text-decoration: none;
                font-size: 24px;
                font-weight: bold;

                &:hover {
                    text-decoration: none;
                }
            }

            &.top-panel-footer-split {
                text-align: left;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &.top-panel-footer-single {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
        }
    }

    .top-panel-big {
        .logo-header {
            border-bottom-left-radius: 25px;
            display: flex;
            justify-content: center;
            position: relative;

            &:before {
                content: '';
                background-color: black;
                position: absolute;
                width: 25px;
                height: 25px;
                bottom: 0;
                left: 0;
                z-index: -1;
            }
        }

        .top-panel-footer div:last-child {
            font-size: 20px;
            font-weight: bold;
        }

        .zakelijk-top {
            position: relative;

            &:before {
                content: '';
                background-color: $color-grey;
                position: absolute;
                width: 25px;
                height: 25px;
                bottom: -25px;
                right: 0;
                z-index: -1;
            }

            &:hover, &:hover:before {
                background-color: #E30613;
            }

            &.active::before {
                background-color: #f7b000;
            }
        }

        .top-panel-header.active {
            background: #f7b000;
        }
    }

    .row-eq-height > [class^="col-"] .top-panel,
    .row-eq-height > [class^="col-"] .top-panel-header {
        height: 100%;
    }

    .row-narrow {
        margin-left: -2px;
        margin-right: -2px;
    }

    .row-narrow > [class^="col-"],
    .row-narrow > [class*=" col-"] {
        padding-left: 2px;
        padding-right: 2px;
    }

    .zakelijk-bottom {
        border-top-right-radius: 25px;
    }

    .top-panel-footer-narrow {
        padding: 10px 5px;
    }

    #tab-content {
        .top-panel-box {
            margin-bottom: 20px;

            .under-collapse {
                overflow: hidden !important;
                border: 0;
                display: flex;
                justify-content: center;
                list-style: none;
                margin-bottom: 0!important;
                padding-bottom: 0!important;

                > li {
                    width: 32%;
                    text-align: center;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    background-color: lightgrey !important;
                }
            }

            .tab-content {
                position: relative;
                padding: 50px 20px 50px 50px;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 20px;
                background-color: lightgrey;

                .close-button {
                    position: absolute;
                    top: 18px;
                    right: 18px;
                    background-color: white;
                    border-radius: 20px;
                    font-weight: bolder;
                    border: 2px solid grey;
                }

                .content-under-tab {
                    max-height: 400px;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        -webkit-appearance: none;
                        width: 20px;
                    }

                    &::-webkit-scrollbar-track {
                        background: tomato;
                        border-left: 9px solid lightgrey;
                        border-right: 9px solid lightgrey;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: black;
                        border-left: 7px solid white;
                        border-right: 7px solid white;
                        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
                    }

                    a {
                        color: #337ab7;
                        text-decoration: none;
                    }

                    a:hover {
                        color: #23527c;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .top-panel-wrapper.row-search {
        margin-bottom: .6rem;
    }

    .review-widget {
        position: relative;
        border: 1px solid #ddd;
        border-top: none;
        border-bottom-left-radius: 20px;
        box-shadow: 0 1px 9px 1px rgba(0, 0, 0, .1);
        padding: .5rem 0;
        margin-bottom: .25rem;

        &::after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: -20px;
            right: -1px;
            border-bottom-left-radius: 0;
            border-right: 1px solid #ddd;
            background: #fff;
            z-index: -1;
            box-shadow: 2px -6px 9px 1px rgba(0, 0, 0, .1);
        }
    }
}

@media screen and (max-width: 767px) {
    .menu-panel {
        .top-panel-big {
            .logo-header {
                justify-content: space-between;
            }
        }

        .navbar-toggler {
            background-color: transparent;
            padding: 0;
            border: 0;

            img {
                height: 90px;
                width: 90px;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .top-panel-header:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 0;
        right: 0;
        background: #000;
    }

    .top-panel[data-target]:hover .top-panel-header:after{
        background: #e30613;
    }
    .top-panel-footer:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: $color-grey;
    }
    .top-panel-footer:after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom-left-radius: 20px;
        background: #000;
        z-index: 1;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .menu-panel {
        .top-panel-wrapper {
            .col-md-3, .col-search {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .menu-panel {
        .zakelijk-bottom {
            border-radius: 0;
        }
    }
}
