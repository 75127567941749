::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: #f2f2f2;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

@media (max-width: 768px) {
    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
    }
}
