.filters{
    padding: 10px 15px 0 15px;
    border-bottom-right-radius: 30px;

    .filter-content {
        .first-section {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            background: white;
            border-top-left-radius: 28px;
            flex-direction: column;
            justify-content: left;
            padding: 10px 15px;
            position: relative;
            z-index: 10;

            &.has-families::before {
                content: '';
                position: absolute;
                background-color: #fff;
                left: 0;
                top: -30px;
                width: 30px;
                height: 30px;
                z-index: 0;
            }

            .first-section-wrapper {
                display: flex;

                .title-radio {
                    font-size: 16px;
                    color: #242424;
                    font-weight: bold;
                    margin: 0;
                }

                .first-radio {
                    width: 65%;
                }

                .first-radio-home {
                    width: 100%;
                    display: flex;
                }

                .content-radio-home {
                    display: flex;
                    margin-left: 10px;
                }

                .second-radio {
                    padding-left: 5px;
                    border-left: 2px solid grey;

                    .content-radio-wrapper {
                        display: flex;
                    }
                }
            }

            &::after {
                content: '';
                width: 30px;
                height: 30px;
                bottom: 0;
                right: 0;
                position: absolute;
            }
        }

        .first-section-family {
            border-top-left-radius: 0;
            border-bottom-right-radius: $gw-border-radius-lg;
        }

        .second-section {
            position: relative;

            .second-section-wrapper {
                display: flex;

                .left-section {
                    flex: 1;
                    padding: 12px;
                    margin-right: 20px;

                    .form-control {
                        background: linear-gradient(to bottom, #fff 0%, #fff 50%, #c3c3c3 50%, #c3c3c3 100%);
                        color: #000;
                        font-weight: bold;
                        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 50%);
                    }

                    [name="flt_brand"] {
                        margin-bottom: 5px;
                    }
                }

                .right-section {
                    flex: 3;

                    .right-section-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 15px 15px 10px;
                        background: #fff;
                        border-bottom-left-radius: 20px;
                        border-top-right-radius: 20px;
                        margin-top: 12px;

                        > * {
                            flex: 100%;
                            display: flex;
                        }
                    }

                    .filter-label {
                        font-weight: bold;
                        color: #242424;
                        margin-right: 15px;
                        margin-bottom: 10px;
                        z-index: 1003;
                        flex-basis: 130px;
                    }

                    .filter-values {
                        display: flex;
                        flex-wrap: wrap;

                        .column-radio {
                            position: relative;
                            display: inline-block;
                            padding-right: 63px;

                            &::before {
                                height: 9px;
                                border-left: 2px solid darkgray;
                                content: "";
                                position: absolute;
                                top: 15px;
                                left: 8px;
                                z-index: 0;
                            }

                            &::after {
                                height: 20px;
                                border-top: 2px solid gray;
                                content: "";
                                position: absolute;
                                top: 8px;
                                left: 10px;
                                width: 100%;
                                z-index: 0;
                            }

                            [type="checkbox"] + label,
                            [type="radio"] + label {
                                padding-left: 0;
                                padding-top: 24px;
                            }

                            &:last-child {
                                padding-right: 0;

                                &::after {
                                    border-top: 0;
                                }
                            }

                            [type="checkbox"] + label:before,
                            [type="radio"] + label:before{
                                background: #fff;
                                z-index: 1;
                            }
                        }
                    }
                }

                .right-section-home {
                    height: 125px;
                    position: relative;
                    overflow: hidden;
                    background: #fff;
                }

                .right-section-home img {
                    border-top-right-radius: 30px;
                }
            }

            .second-section-wrapper-home {
                background-color: #d2d2d2;
            }
        }

        .third-section {
            .nav-abo-sim {
                display: flex;
                text-align: center;
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                margin-top: 10px;

                li {
                    flex: 1;
                    display: flex;
                    border-radius: 0 30px 30px 0;
                    position: relative;
                    cursor: pointer;

                    > a {
                        border-radius: 0 30px 30px 0;
                        background-color: #545454;
                        box-shadow: 7px 0 0 0 rgba(0, 0, 0, 0.3);
                        margin-left: 7px;
                    }

                    &.active {
                        > a,
                        > a:focus,
                        > a:hover {
                            background: #f9d265;
                            border: 4px solid #f9d265;
                            color: #000;
                            font-size: 18px;
                        }

                        > a {
                            border-radius: 0 30px 30px 0;
                        }

                        .fill-bg {
                            background-color: #f9d265;
                        }

                        &:first-child {
                            > a {
                                border-top-left-radius: 30px;
                                border-bottom-left-radius: 0;
                            }
                        }

                        &:last-child {
                            > a {
                                border-top-right-radius: 0;
                                border-bottom-left-radius: 0;
                            }
                        }
                    }

                    &:not(.active) {
                        > a {
                            border: 4px solid #ccc;
                            border-left: none;
                        }

                        .fill-bg {
                            border-top: 4px solid #ccc;
                            border-bottom: 4px solid #ccc;

                            .fill {
                                &::before {
                                    border-radius: 0 40px 40px 0;
                                }
                            }
                        }

                        &:hover, &:focus {
                            > a, .fill-bg {
                                background-color: #f6b000;
                            }
                        }
                    }

                    &:first-child {
                        > a {
                            margin-left: 0;
                            border-radius: 0 30px 30px 30px;
                        }

                        &:not(.active) {
                            a {
                                border-left: 4px solid #ccc;
                            }
                        }
                    }

                    &:last-child {
                        > a {
                            box-shadow: none;
                            border-top-right-radius: 0;
                            border-bottom-left-radius: 30px;
                            border-top-left-radius: 30px;
                        }
                    }

                    .nav-abo-sim-text {
                        flex: 1;
                        position: relative;
                        padding: 10px 5px;
                    }

                    .fill-bg {
                        position: absolute;
                        top: 0;
                        left: -25px;
                        height: 100%;
                        width: 32px;
                        background-color: #545454;
                        z-index: 1;

                        .fill {
                            border-radius: 0 40px 40px 0;

                            &::before {
                                content: '';
                                display: inline-block;
                                vertical-align: middle;
                                background-color: #545454;
                                border-radius: 0 30px 30px 0;
                            }
                        }
                    }

                    &.extra {
                        > a {
                            border: 4px solid #ccc;
                        }
                    }

                    .a-link {
                        color: #f9d265;
                        text-decoration: underline;
                        margin-right: 5px;
                    }
                }

                a {
                    color: #fff;
                    font-size: 15px;
                    font-weight: bold;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 5;
                }
            }

            .title-container {
                text-align: center;
                color: #999999;
                margin: 0;

                .page-title {
                    padding: 10px 0;
                    font-weight: 600;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 24px;
                    margin-bottom: 0;
                }
            }
        }

        [type="checkbox"],
        [type="radio"] {
            position: absolute;
            left: -9999px;

            & + label {
                position: relative;
                padding-left: 23px;
                padding-right: 16px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                font-weight: bolder;
                color: #242424;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 18px;
                    height: 18px;
                    border: 2px solid grey;
                    background: transparent;
                }
                &:after {
                    content: '';
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    z-index: 2;
                    transition: all 0.2s ease;
                }
            }

            &:not(:checked) + label:after {
                opacity: 0;
                transform: scale(0);
            }

            &:checked {
                & + label {
                    color: #242424;
                }
            }
        }

        [type="radio"] {
            & + label {
                &:before, &:after {
                    border-radius: 100%;
                }

                &:after {
                    background: #f9d265;
                }
            }
        }

        [type="checkbox"] {
            & + label {
                &:before {
                    border-radius: 4px;
                }

                &:after {
                    content: '\f00c';
                    font-family: 'Font Awesome\ 5 Free';
                    font-weight: 900;
                    top: 0;
                    left: 0.15rem;
                    color: #000;
                    font-size: 0.8rem;
                }
            }
        }

        .select-wrapper {
            position: relative;

            .arrow {
                background: $form-select-indicator;
                position: absolute;
                right: calc(2.25rem/2 - 16px / 2);
                top: calc(2.375rem / 2 - 16px / 2);
                width: 16px;
                height: 16px;
            }
        }

        .form-select {
            background: linear-gradient(to bottom, #fff 0%, #fff 50%, #c3c3c3 50%, #c3c3c3 100%);
            color: #000;
            font-weight: bold;
            box-shadow: 0 2px 2px 0 rgb(0 0 0 / 50%);
            width: 100%;
        }

        [name="flt_brand"] {
            margin-bottom: 5px;
        }

        .right-section {
            border-bottom-left-radius: 20px;
            border-top-right-radius: 0;
            flex: 3;

            .right-section-wrapper {
                display: flex;
                flex-wrap: wrap;
                padding: 15px 15px 10px;

                > * {
                    flex: 100%;
                    display: flex;
                }
            }

            .filter-label {
                font-weight: bold;
                color: #242424;
                margin-right: 15px;
                margin-bottom: 10px;
                z-index: 1003;
                flex-basis: 130px;
            }

            .filter-values {
                display: flex;
                flex-wrap: wrap;

                .column-radio {
                    position: relative;
                    display: inline-block;
                    padding-right: 63px;

                    &::before {
                        height: 9px;
                        border-left: 2px solid darkgray;
                        content: "";
                        position: absolute;
                        top: 15px;
                        left: 8px;
                        z-index: 0;
                    }

                    &::after {
                        height: 20px;
                        border-top: 2px solid gray;
                        content: "";
                        position: absolute;
                        top: 8px;
                        left: 10px;
                        width: 100%;
                        z-index: 0;
                    }

                    [type="checkbox"] + label,
                    [type="radio"] + label {
                        padding-left: 0;
                        padding-top: 24px;
                    }

                    &:last-child {
                        padding-right: 0;

                        &::after {
                            border-top: 0;
                        }
                    }

                    [type="checkbox"] + label:before,
                    [type="radio"] + label:before{
                        background: #d3d3d3;
                        z-index: 1;
                    }
                }
            }
        }

        .family-filter {
            position: relative;
            padding: 2px;
            border-top-left-radius: $gw-border-radius-lg;
            border: 2px solid #fff;
            display: flex;
            flex-wrap: wrap;
            z-index: 100;

            .family-filter-inner {
                padding: 10px 15px 10px 15px;
                width: 100%;
                border-top-left-radius: $gw-border-radius-lg;

                div {
                    display: inline-block;
                }
            }

            [type=checkbox]:checked + label,
            [type=checkbox] + label,
            [type=radio]:checked + label,
            [type=radio] + label {
                color: #fff;
            }
        }

        .device-only-filter-wrap {
            padding: 10px 0;

            .label-white {
                color: #fff;
                font-weight: 600;
                font-size: .875rem;
            }

            .label-dark {
                color: #333333;
                font-weight: 600;
                font-size: .875rem;
                margin-left: 10px;
            }

            .col-item:not(:last-child) {
                flex: 1;
            }

            .col-item {
                padding: 0 12px;

                > div {
                    min-height: 64px;
                }
            }

            .multiselect {
                .multiselect-placeholder {
                    color: #212529;
                }

                .multiselect-tag {
                    background: rgba($color-topdeals, .9);
                }

                .multiselect-option.is-selected {
                    background: var(--ms-option-bg-pointed, #ededef);
                    color: var(--ms-option-color-pointed,#1f2937);
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .filters {
        .filter-content {
            .device-only-filter-wrap {
                flex-direction: column;

                .col-item {
                    width: 100%;
                    margin-bottom: 1rem;
                }
            }

            .first-section-mobile {
                .first-radio-mobile {
                    text-align: center;
                    color: white;
                    margin-bottom: 10px;

                    [type="checkbox"]:checked,
                    [type="radio"]:checked {
                        & + label {
                            color: #fff;
                        }
                    }

                    [type="checkbox"]:not(:checked),
                    [type="radio"]:not(:checked) {
                        & + label {
                            color: #e2e2e2;
                        }
                    }
                }

                .show-subscription {
                    padding: 5px 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    color: black;
                    background: white;
                }

                .tab-radio {
                    font-size: 16px;
                    color: #242424;
                    font-weight: bold;
                    margin: 0;
                }

                .radio-1 {
                    background-color: white;
                    display: flex;
                    flex-direction: column;
                    justify-content: left;
                    padding: 10px 15px;
                    position: relative;
                    z-index: 10;
                    margin-bottom: 10px;

                    .second-radio {
                        padding-left: 5px;

                        & > div {
                            margin: 0;
                            padding-left: 8px;
                        }
                    }
                }
            }

            .second-section {
                .second-section-wrapper {
                    display: block;

                    .right-section {
                        border-bottom-left-radius: 0;
                        margin: 0;
                    }

                    .left-section {
                        padding: 0;
                        margin: 0;
                    }
                }
            }

            .third-section {
                .nav-abo-sim {
                    display: block;

                    li {
                        &.active {
                            &:first-child {
                                > a {
                                    border-radius: 0;
                                }
                            }
                        }

                        &:not(.active) {
                            &:first-child {
                                > a {
                                    border-top: 4px solid #ccc;
                                }
                            }
                        }

                        &.extra {
                            a {
                                border-top: 4px solid #ccc;
                            }
                        }

                        .nav-abo-sim-text {
                            padding: 6px 5px;
                        }

                        .fill-bg {
                            left: 0;
                            top: -25px;
                            height: 32px;
                            width: 100%;
                        }

                        & + li {
                            margin: 0;
                        }

                        &:nth-child(even) {
                            a {
                                border-radius: 0;
                            }
                        }

                        &:nth-child(odd) {
                            a {
                                border-radius: 0;
                            }
                        }

                        &:not(.active) {
                            &.extra {
                                a {
                                    border-top: 4px solid #ccc;
                                }
                            }

                            > a {
                                border: 4px solid #ccc;
                                border-top: none;
                            }

                            .fill-bg {
                                border: none;
                                border-left: 4px solid #ccc;
                                border-right: 4px solid #ccc;
                            }

                            [data-type="toestel"] a {
                                border-top: 4px solid #ccc;
                            }
                        }

                        a, a:hover {
                            font-size: 15px;
                            margin-left: 0;
                            margin-top: 7px;
                            box-shadow: 0 7px 0 0 rgba(0, 0, 0, 0.3);
                        }
                    }
                }
            }

            .right-section {
                border-bottom-left-radius: 0;
                margin: 0;
            }
        }

        .family-filter-mobile {
            background: none;
            border: none !important;
            border-bottom: 2px solid #fff !important;
            border-top-left-radius: 0 !important;
            margin-bottom: 10px;
        }
    }
}
