.pricing-details-box {
    font-size: .9rem;

    .main-title {
        font-size: 1.2rem;
        font-weight: 600;
    }

    h4 {
        font-size: 1rem;
        font-weight: 600;
    }

    .section {
        margin-bottom: 1rem;
        padding: 1rem 0;
    }

    .section-title, h3 {
        font-size: .9rem;
        font-weight: 600;
    }
}
