.connection {
    position: relative;
    margin: 0 6px 0 8px;

    .signal {
        font-size: 0.813rem;
    }

    .connection-speed {
        position: absolute;
        left: -6px;
        top: -5px;
        font-size: 0.625rem;
    }
}
